module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Magnus Art","short_name":"Magnus Art","description":"We are team of web developers focusing on delivering high quality digital products.","lang":"en","start_url":"/index.html","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"/builds/SebasCode8/magnusart-gatsby/static/img/favicon.png","icons":[{"src":"favicons/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/favicon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/favicon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"localize":[{"start_url":"/cz/index.html","lang":"cs","name":"Magnus Art","short_name":"Magnus Art","description":"Sbírka umění galerie Magnus Art"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6694559b04c027b23593eab0e4a54532"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-27009070-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
